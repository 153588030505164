import React from 'react';
import { Link } from 'gatsby';
import styled from 'react-emotion';

const StyledLink = styled(Link)`
  padding: .5em 0 .5em 1em;
  position: relative;
  font-size: 1.2em;
  display: block;
  width: 100%;
  color: #666;
  ::before {
    content: "•";
    position: absolute;
    top: .5em;
    left: .1em;
  }
  text-decoration: none;
`;

const StyledA = styled.a`
  padding: .5em 0 .5em 1em;
  position: relative;
  font-size: 1.2em;
  display: block;
  width: 100%;
  color: #666;
  ::before {
    content: "•";
    position: absolute;
    top: .5em;
    left: .1em;
  }
  text-decoration: none;
`;


const StyledP = styled.small`
  padding: 0 1em;
`;

const StyledDiv = styled.div`
  padding: .5em 0;
`;

const Hit = (props) => {
  const {
    hit: {
      fields: {
        slug,
        title,
        body,
      },
      sys: {
        contentType: {
          sys: {
            id,
          },
        },
      },
    },
  } = props;
  const {
    hit: {
      sys,
    },
  } = props;

  return (
    <StyledDiv>
      { id === 'modules' ? (
        <div>
          <StyledA href={`https://learn.ablehomecarellc.com/module?${sys.id}`} target="_blank">
            {title['en-US']}
          </StyledA>
          <StyledP>{body['en-US'].substring(0, 300)}</StyledP>
        </div>
      ) : (
        <div>
          <StyledLink to={slug['en-US']}>
            {title['en-US']}
          </StyledLink>
          <StyledP>{body['en-US'].substring(0, 300)}</StyledP>
        </div>
      )}
    </StyledDiv>
  );
};
export default Hit;
