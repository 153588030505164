import React from 'react';
import { graphql } from 'gatsby';
import LoginButton from 'components/Login';

import '@react-website-themes/classy-docs/styles/variables';
import '@react-website-themes/classy-docs/styles/global';

import Helmet from 'react-helmet';
import Branding from '@react-website-themes/classy-docs/components/Branding';
import Footer from '@react-website-themes/classy-docs/components/Footer';
import Header from '@react-website-themes/classy-docs/components/Header';
import Hero from '@react-website-themes/classy-docs/components/Hero';
import Layout from '@react-website-themes/classy-docs/components/Layout';
import Heading from '@react-website-themes/classy-docs/components/Heading';
import Menu from '@react-website-themes/classy-docs/components/Menu';
import Article from '@react-website-themes/classy-docs/components/Article';
import LogoutButton from 'components/Logout';
import Hit from 'components/Hit';
import Logo from 'assets/images/logo.png';
import { css } from 'emotion';
import styled from 'react-emotion';
import {
  InstantSearch,
  SearchBox,
  Hits,
  Stats,
  Pagination,
  Configure,
} from 'react-instantsearch/dom';

import config from 'meta/config';
import menuItems from 'meta/menu';

import AlgoliaLogo from 'assets/images/algolia.svg';
import { isAuthenticated } from '../Auth/AuthService';

const HideCredits = css`
  .credits {
    display: none;
  }
`;

const SearchDiv = styled.div`
  margin-top: -1em;
  .ais-SearchBox {
    width: 100%;
  }
  .ais-SearchBox-form {
    position: relative;
    border-bottom: 1px solid #aaa;
    display: flex;
    justify-content: space-between;
  }
  .ais-SearchBox-input {
    border: none;
    padding: .2em;
    font-size: 3em;
    flex-grow: 1;
  }
  .ais-SearchBox-submit {
    background: none;
    border: none;
    fill: #666;
    flex-grow: 0
  }
  .ais-SearchBox-reset {
    background: none;
    border: none;
    fill: #666;
    flex-grow: 0
  }
  .ais-Stats {
    margin: .5em 0 2em .3em;
    font-size: .9em;
    color: #999;
    display: block;
  }
  .ais-Hits-list {
    list-style: none;
    padding: 0;
  }
  .ais-Pagination-list {
    display: flex;
    list-style: none;
    justify-content: center;
    padding: 0;
  }
  .ais-Pagination-item {
    a, span {
      color: #666;
      font-size: 1.2em;
      display: block;
      padding: .5em .5em 2em;
    }
    a:hover {
      font-weight: 700;
    }
    .ais-Pagination-link--selected{
      color: ${props => props.theme.brand};
      font-weight: 700;
    }
    .ais-Pagination-item--firstPage,.ais-Pagination-item--previousPage, .ais-Pagination-item--nextPage {
      a,  span: {
        padding: .4em .5em .6em;
      }
    }
  }
  a {
    font-weight: 400;
  }
`;

class SearchPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
    };
  }

  componentDidMount() {
    this.setAuthentication();
  }

  setAuthentication() {
    this.setState({ authenticated: isAuthenticated() });
  }

  render() {
    const {
      location: {
        pathname,
      },
      data: {
        hero: {
          edges: heroEdges,
        },
        footerLinks: {
          edges: footerLinksEdges,
        },
        copyright: {
          edges: copyrightEdges,
        },
      },
    } = this.props;

    const {
      node: {
        body: {
          childMarkdownRemark: {
            html: footerLinksHTML,
          },
        },
      },
    } = footerLinksEdges[0];

    const {
      node: {
        body: {
          childMarkdownRemark: {
            html: copyrightHTML,
          },
        },
      },
    } = copyrightEdges[0];

    const {
      node: {
        body: {
          childMarkdownRemark: {
            html: heroHTML,
          },
        },
      },
    } = heroEdges[0];

    const {
      headerTitle,
      headerSubTitle,
    } = config;

    const { authenticated } = this.state;

    return (
      <Layout>
        <Helmet title="Search | Able Policies" />
        <Header>
          <Branding title={headerTitle} subTitle={headerSubTitle} />
          <Menu items={menuItems} />
          {' '}
          {authenticated && (
            <LogoutButton
              setAuthentication={this.setAuthentication}
              pathname={pathname}
            />)
          }
        </Header>
        {
          !authenticated && (
            <Hero>
              <img src={Logo} className="image" alt="site logo" />
              <div dangerouslySetInnerHTML={{
                __html: heroHTML,
              }}
              />
              <LoginButton setAuthentication={this.setAuthentication} pathname={pathname} />
            </Hero>
          )
        }
        {
          authenticated && (
            <Article>
              <Heading title="Search" />
              <SearchDiv>
                <InstantSearch appId={`${process.env.Algolia_APPID}`} apiKey={`${process.env.Algolia_SearchKey}`} indexName="portal">
                  <SearchBox translations={{
                    placeholder: '',
                  }}
                  />
                  <Configure hitsPerPage={5} />
                  <Hits hitComponent={Hit} />
                  <Stats />
                  <Pagination maxPages={5} />
                </InstantSearch>
              </SearchDiv>
              <img src={AlgoliaLogo} alt="Algolia search logo" />
            </Article>
          )
        }
        <Footer customStyle={HideCredits} links={footerLinksHTML} copyright={copyrightHTML} />
      </Layout>
    );
  }
}

export default SearchPage;

export const query = graphql`
  query {
    hero: allContentfulParts(
      filter: {
            title: { eq: "hero"}
          }) {
      edges {
        node {
          id
          title
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    footerLinks: allContentfulParts(
      filter: {
            title: { eq: "footerLinks"}
          }) {
      edges {
        node {
          id
          title
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    copyright: allContentfulParts(
      filter: {
            title: { eq: "copyrightNote"}
          }) {
      edges {
        node {
          id
          title
          body {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
