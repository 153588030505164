/* eslint-disable */
import React, { Component } from 'react';
import styled from 'react-emotion';
import { navigate } from 'gatsby';
import LogOutIcon from 'react-feather/dist/icons/log-out';
import { Logout } from '../Auth/AuthService';

const StyledUl = styled.ul`
  align-items: center;
  display: flex;
  z-index: 1;
  background: #fff;
  justify-content: center;
  list-style: none;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;

const StyledLI = styled.li`
  padding: 0 5px;
  line-height: 1;
`;

const StyledA = styled.a`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  color: var(--lightTextColor);
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background: var(--firstActiveColor);
    width: 20px;
    height: 0px;
    transition: 0.3s;
    transform: translateY(8px);
  }

  &.active:before {
    height: 5px;
    transform: translateY(0);
  }

  &:not(.active) {
    @media (hover: hover) {
      &:hover:before {
        height: 5px;
        transform: translateY(.8em);
        background: var(--secondActiveColor);
      }
    }
  }

  svg {
    margin: 0 5px 0 0;
    height: 20px;
    width: 20px;
  }
`;

class LogoutButton extends Component {
  constructor(props) {
    super(props);
    this.startLogout = this.startLogout.bind(this);
  }

  startLogout() {
    Logout();
    navigate('/');
  }

  render() {
    return (
      <StyledUl>
        <StyledLI>
          <StyledA onClick={this.startLogout}>
            <LogOutIcon />
            <span>Log Out</span>
          </StyledA>
        </StyledLI>
      </StyledUl>
    );
  }
}

export default LogoutButton;
