import React, { Component } from 'react';
import styled from 'react-emotion';
import { connect } from 'react-redux';
import Article from '@react-website-themes/classy-docs/components/Article';
import { Login } from '../Auth/AuthService';
import { setCallbackLink } from '../actions';


const HeroButton = styled.button`
  align-items: center;
  border-radius: 3px;
  color: var(--firstActiveColor);
  font-size: 1em;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  padding: 1em 2em;
  background: #f9f9f9;
  margin: 40px 0 0;
`;

const mapStateToProps = ({ callbackLink }) => ({ callbackLink });

const mapDispatchToProps = dispatch => ({
  setCallbackLink: callbackLink => dispatch(setCallbackLink(callbackLink)),
});

class LoginButton extends Component {
  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
  }

  componentDidMount() {
    const { setCallbackLink: setLink, pathname } = this.props;
    setLink(pathname);
  }

  login() {
    Login();
    const { setAuthentication } = this.props;
    setAuthentication();
  }

  render() {
    return (
      <Article>
        <HeroButton onClick={this.login}>
        Log In
        </HeroButton>
      </Article>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginButton);
